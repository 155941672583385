export default (branch, copyKey, paymentType) => {

    if (typeof branch === 'undefined') {
        branch = 'SA'
    }

    const SACopy = {
        'preExistingIssues': `Please be aware that we don’t automatically provide assistance to workers who have an issue when they join. If this is you, after you join, you will need to contact us to seek approval for assistance with that issue.`,
        'membershipAgreement': `I HEREBY apply for membership of the Shop, Distributive and Allied Employees’ Association, South Australian Branch as registered under the Fair Work (Registered Organisations) Act 2009 (Cth) and (if eligible) the Shop, Distributive and Allied Employees’ Association, South Australian Branch as registered under the Fair Work Act 1994 (SA). I agree to comply with and be bound by the Rules of each Association (SDA) as ammended from time to time. I acknowledge the SDA is affiliated to the Australian Council of Trade Unions, the Australian Labor Party and Union Network International. I authorise the SDA to use my personal information in order to communicate with me, for direct marketing, research and member surveys. Privacy Act 1998 - The SDA is bound by the Privacy Act. This information is collected to enable the SDA to contact you about matters relating to your SDA membership, and to ensure that we have the necessary information to represent your employment and related interests. A copy of the SDA's Privacy Statement is available from the SDA office and our website.`,
        'paymentAuthority': {
            'creditCard': `I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union South Australia has deemed payable.`,
            'directDebit': `I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union South Australia has deemed payable.`
        },
        'paymentAgreement': {
            'payroll': `Please enrol me as a member of the Shop, Distributive and Allied Employees’ Association. I pledge myself to comply with the Rules of the Association and the Branch to which I am attached and any amendments or additions duly made to such Rules in accordance with the Rules and the Fair Work (Registered Organisations) Act 2009. I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company.

            I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company

            I am aware that the financial obligations arising from membership and the circumstances and manner in which a member may resign are set out in certified Rules which are available for inspection at the office of the General Manager of Fair Work Australia or by appointment at the registered office of a branch of the Association.`,
            'creditCard': `By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>`,
            'directDebit': `By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>`
        },
        'joinSuccess': {
            "rows": [
                {
                    "col1": {
                    "html": "<h2>Congratulations! You’re now a member of one of Australia’s largest unions.</h2><p>In the next few days you will receive an email with access to the Members Portal, followed by a Welcome Pack in the mail.</p><p>Remember, if you need help with a workplace issue, you can always call us on <a href=\"tel:0881391000\">8139 1000</a>. We are here to answer all your workplace queries, no matter how big or small.</p>"
                    },
                    "col2": {
                        "html": "<img src='/SA-Application-Complete-Image1.jpg'/>",
                        "fullWidth": true
                    }
                },
                {
                    "col1": {
                        "html": "<img src='/SA-Application-Complete-Image2.jpg'/>",
                        "fullWidth": true
                    },
                    "col2": {
                        "html": "<h2>Remember...</h2><p>SDA membership is the best workplace insurance you can have!</p>"
                    }
                }
            ]
        },
        'notesOnPayment': [
            "Your membership can be cancelled at any time",
            "Membership fees are tax-deductible",
            "Transactions will appear on your statement as ‘SDA UNION’"
        ],
        'notesOnPaymentPayroll': [
            "Membership fees will be automatically deducted from your pay and will appear on your payslip",
            "If you don’t work any hours that pay cycle, you won’t be charged any fees",
            "Your membership can be cancelled at any time",
            "Membership fees are tax-deductible",
            "Transactions will appear on your statement as ‘SDA UNION’"
        ]
    }

    const branchCopy = {
        'SA': SACopy,
        'NT': SACopy,
        'Broken Hill': SACopy,
        'WA': {
            'preExistingIssues':
            `Please be aware that the SDA will not assist with issues which commenced prior to joining. I understand that the SDA is not able to assist me with any pre-existing issues
            `,
            'membershipAgreement':
            `To the Secretary, The Shop, Distributive and Allied Employees’ Association of WA:

I wish to apply for membership of the Association, and I agree to be governed by the Association’s rules. I declare that the information provided by me in completing this form is true and correct. I understand that if I wish to cancel my membership at any time I must do so in writing, with a handwritten signature, by emailing sda@sdawa.asn.au or by post to GPO Box 2556 Perth WA 6001.
            `,
            'paymentAuthority': {
                'creditCard': `We use Paystream to securely process credit card payments. The Paystream is Level 1 PCI DSS Compliant payment gateway provider, which mean they adhere to the highest industry security standards when handling credit card information.

                Upon choosing direct debit as my payment method, I Confirm that the details I give are true and correct. I request and authorise The Shop, Distributive and Allied Employees Association of Western Australia (User ID 069367) to debit my nominated credit card each month. I understand that my direct debit request and membership will remain in force until cancelled in writing by me. I will advise the union of any changes to my credit card and contact details.
                `,
                'directDebit': `Upon choosing direct debit as my payment method, I Confirm that the details I give are true and correct. I request and authorise The Shop, Distributive and Allied Employees Association of Western Australia (User ID 069367) to debit my nominated account each month. I understand that my direct debit request and membership will remain in force until cancelled in writing by me. I will advise the union of any changes to my account and contact details.`
            },
            'paymentAgreement': {
                'payroll': `Please enrol me as a member of the Shop, Distributive and Allied Employees’ Association. I pledge myself to comply with the Rules of the Association and the Branch to which I am attached and any amendments or additions duly made to such Rules in accordance with the Rules and the Fair Work (Registered Organisations) Act 2009. I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company.

                I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company

                I am aware that the financial obligations arising from membership and the circumstances and manner in which a member may resign are set out in certified Rules which are available for inspection at the office of the General Manager of Fair Work Australia or by appointment at the registered office of a branch of the Association.`,
                'creditCard': `I understand and agree to the <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DDRServiceAgreement-WA.pdf">terms and conditions</a> set out in this Direct Debit Request and of the Direct Debit Request Service Agreement.`,
                'directDebit': `I understand and agree to the <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DDRServiceAgreement-WA.pdf">terms and conditions</a> set out in this Direct Debit Request and of the Direct Debit Request Service Agreement.`
            },
            'joinSuccess': {
                "rows": [
                    {
                        "col1": {
                            "html": "<h2>Welcome to the SDA</h2><p>By joining your Union you have added your voice to the call for a fair go at work.</p></br><p>Please take some time to check out our website and familiarize yourself with all of the benefits your SDA membership provides.</p>"
                        },
                        "col2": {
                            "html": "<img src='/form_success_1.png'/>",
                            "fullWidth": true
                        }
                    },
                    {
                        "col1": {
                            "html": "<img src='/form_success_2.png'/>",
                            "fullWidth": true
                        },
                        "col2": {
                            "html": "<p>Remember, the SDA is here to serve you.  If you have any queries about your rights at work or something that is happening in your workplace, call us on:</p></br><h3>1300 ASK SDA (1300 275 732)</h3>"
                        }
                    }
                ]
            },
            'notesOnPayment': [
                "Your membership can be cancelled at any time",
                "Membership fees are tax-deductible",
            ],
            'notesOnPaymentPayroll': [
                "Membership fees will be automatically deducted from your pay and will appear on your payslip",
                "If you don’t work any hours that pay cycle, you won’t be charged any fees",
                "Your membership can be cancelled at any time",
                "Membership fees are tax-deductible",
                "Transactions will appear on your statement as ‘SDA UNION’"
            ]
        },
        'TAS': {
            'preExistingIssues': `Any help given by the SDA cannot pre-date my membership and that while the Union can still offer me advice, it cannot assist me with pre-existing problems.`,
            'membershipAgreement': `I hereby agree to become a member of the Shop Distributive and Allied Employees’ Association, Tasmanian Branch. I pledge myself to comply with the Rules of the Association, and any amendments or additions which may be duly made to such rules. I authorise my employer to deduct from my wages the contribution prescribed by Rule 6 of the Shop Distributive and Allied Employees Association, Tasmanian Branch, the fees prescribed by the rules as varied from time to time. Such deduction shall be paid to the above for mentioned Association on behalf of the undersigned member as Union Subscriptions.`,
            'paymentAuthority': {
                'creditCard': `I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Tasmania has deemed payable.`,
                'directDebit': `I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Tasmania has deemed payable.`
            },
            'paymentAgreement': {
                'payroll': `Please enrol me as a member of the Shop, Distributive and Allied Employees’ Association. I pledge myself to comply with the Rules of the Association and the Branch to which I am attached and any amendments or additions duly made to such Rules in accordance with the Rules and the Fair Work (Registered Organisations) Act 2009. I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company.

                I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company

                I am aware that the financial obligations arising from membership and the circumstances and manner in which a member may resign are set out in certified Rules which are available for inspection at the office of the General Manager of Fair Work Australia or by appointment at the registered office of a branch of the Association.`,
                'creditCard': `By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>`,
                'directDebit': `By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>`
            },
            'joinSuccess': {
                "rows": [
                    {
                        "col1": {
                            "html": "<h2>Welcome to the SDA!</h2><p>If you chose payroll deduction as your payment method, soon you will receive an information pack and membership card. However, you can take advantage of our services and benefits immediately. If you have a question, contact us – we have all the answers.</p><p>If you opted to receive a call, we'll call you soon to confirm your direct debit or credit card details to complete your application.</p>"
                        },
                        "col2": {
                            "html": "<img src='/Dylan Brooks.jpg'/>",
                            "fullWidth": true
                        }
                    },
                    {
                        "col1": {
                            "html": "<img src='/IMG_1861.jpg'/>",
                            "fullWidth": true
                        },
                        "col2": {
                            "html": `<p>There are many ways that we will communicate with you – through our website, regular emails and social media, even the SDA notice board or a visit to your workplace. We like to hear from you too either by phone, email or messaging through Social Media.</p>
                            <p>
                                <a href="https://www.sdatas.asn.au/member-benefits/" target="_blank">https://www.sdatas.asn.au/member-benefits/</a>
                                <br/>Contact number: <a href="tel:1300 152 851">1300 152 851</a>
                                <br/><a href="mailto:secretary@sdatas.asn.au">secretary@sdatas.asn.au</a>
                            </p>`
                        }
                    }
                ]
            },
            'notesOnPayment': undefined,
            'notesOnPaymentPayroll': undefined
        },
        'QLD': {
            'preExistingIssues': `I understand that on receipt of this ‘electronic’ information, the SDA may contact me by phone, email or letter requesting further details that will determine my eligibility for membership. I further understand that the SDA can only represent my interests from the date that membership is accepted. I also note that under no circumstances will the SDA become involved in issues or disputes occurring before that date.`,
            'membershipAgreement': `Please enrol me as a member of the Shop, Distributive and Allied Employees’ Association. I pledge myself to comply with the Rules of the Association and the Branch to which I am attached and any amendments or additions duly made to such Rules in accordance with the Rules and the Fair Work (Registered Organisations) Act 2009.

            I am aware that the financial obligations arising from membership and the circumstances and manner in which a member may resign are set out in certified Rules which are available for inspection at the office of the General Manager of Fair Work Australia or by appointment at the registered office of a branch of the Association.`,
            'paymentAuthority': {
                'creditCard': `I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Queensland has deemed payable.`,
                'directDebit': `I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Queensland has deemed payable.`
            },
            'paymentAgreement': {
                'creditCard': `By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>`,
                'directDebit': `By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>`,
                'payroll': `I hereby authorise the employer, or any successor or transmitee of the employer, to deduct from my wages the appropriate membership fee in accordance with the Association’s Schedule of fees and to pay the monies so deducted to the appropriate account of the Association and to provide the Association updated personal information relevant to my membership. This authority shall remain in force as long as I remain an eligible employee of the Company`
            },
            'joinSuccess': {
                "rows": [
                    {
                        "col1": {
                            "html": "<h2>Congratulations!</h2><p>You’re now a member of one of Australia’s largest unions, joining over 220,000 other members Australia wide. As an SDA member, you’re covered when issues or problems arise at work. You're also helping improve pay and conditions in your workplace. We'll be in touch with your membership details soon.</p>"
                        },
                        "col2": {
                            "html": "<img src='/form_success_1.png'/>",
                            "fullWidth": true
                        }
                    },
                    {
                        "col1": {
                            "html": "<img src='/form_success_2.png'/>",
                            "fullWidth": true
                        },
                        "col2": {
                            "html": "<p>The SDA is at work with you and membership is the best workplace insurance you can have! Feel free to contact us on 07 3833 9500 for confidential advice and assistance. </p>"
                        }
                    }
                ]
            },
            'notesOnPayment': [
                `Our website is safe and your payment details will be securely processed. If you have any concerns, please contact the SDA office on 07 3833 9500.`
            ],
            'notesOnPaymentPayroll': [
                "Membership fees will be automatically deducted from your pay and will appear on your payslip",
                "If you don’t work any hours that pay cycle, you won’t be charged any fees",
                `Our website is safe and your payment details will be securely processed. If you have any concerns, please contact the SDA office on 07 3833 9500.`
            ]
        },
        'Newcastle': {
            'preExistingIssues': `Any help given by the SDA cannot pre-date my membership and that while the union can still offer me advice, it can’t assist me with pre-existing problems.`,
            'membershipAgreement': `Privacy Act 1998 - The SDA is bound by the Privacy Act. This information is collected to enable the Union to contact you about matters relating to your Union membership, and to ensure we have the necessary information to represent your employment and related interests. A copy of the Union’s Privacy Statement is available from Union officials, the office and our website. I HEREBY agree to become a member of the Shop, Distributive and Allied Employees Association (SDA) Newcastle & Northern Branch. I pledge myself to comply with the Rules of the Association and its Branches, and with any amendments or additions which may be duly made to such rules. I authorise the SDA to deduct from my nominated Bank Account or Credit Card the contribution prescribed by the rules of the SDA, Newcastle & Northern Branch, as varied from time to time. I will advise the SDA of changed to my hours of work or employment status. I acknowledge the SDA is affiliated to the Australian Council of Trade Unions, the Australian Labor Party and Union Network International. The information I have provided can be used for purposes such as communicating with members, direct marketing, research and member surveys.`,
            'paymentAuthority': {
                'creditCard': undefined,
                'directDebit': undefined
            },
            'paymentAgreement': {
                'creditCard': undefined,
                'directDebit': undefined,
                'payroll': undefined
            },
            'joinSuccess': {
                "rows": [
                    {
                        "col1": {
                            "html": "<h2>Content not provided</h2>"
                        },
                        "col2": {
                            "html": "<img src='/form_success_1.png'/>",
                            "fullWidth": true
                        }
                    },
                    {
                        "col1": {
                            "html": "<img src='/form_success_2.png'/>",
                            "fullWidth": true
                        },
                        "col2": {
                            "html": "<h2>Content not provided</h2>"
                        }
                    }
                ]
            },
            'notesOnPayment': undefined,
            'notesOnPaymentPayroll': undefined
        }
    }

    if (paymentType) {
        return branchCopy?.[branch]?.[copyKey]?.[paymentType]
    } else {
        return branchCopy?.[branch]?.[copyKey]
    }
}
